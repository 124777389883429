<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">我的贷款</h5>
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :rowKey="(record, index) => { return index }">

      <template slot="text" slot-scope="text">
        <a style="color: #1F1F1F">{{ text }}</a>
      </template>

      <template slot-scope="loanId" slot="loanId">
        <router-link style="color: #1F1F1F" :to="{path: '/repay', query: {loanId: loanId}}">{{ loanId }}</router-link>
      </template>

      <template slot="repayType" slot-scope="mode">
        <a-tag v-if="mode === '0'">等额本息</a-tag>
        <a-tag v-else-if="mode === '1'">等额本金</a-tag>
        <a-tag v-else-if="mode === '2'">一次性还本付息</a-tag>
        <a-tag v-else>其他</a-tag>
      </template>

      <template slot="price" slot-scope="price">
        <a style="color: #1F1F1F">{{ moneyFormat(price) }}元</a>
      </template>

      <template slot="restMoney" slot-scope="restMoney">
        <a style="color: #1F1F1F">{{ moneyFormat(restMoney) }}元</a>
      </template>

      <template slot="rate" slot-scope="rate">
        <a style="color: #1F1F1F">{{ rate }}%</a>
      </template>

      <template slot="term" slot-scope="term">
        <a style="color: #1F1F1F">{{ term }}月</a>
      </template>

      <template slot="startDate" slot-scope="startDate">
        <a style="color: #1F1F1F">{{ parseTime(startDate, '{y}-{m}-{d}') }}</a>
      </template>

      <template slot="endDate" slot-scope="endDate">
        <a style="color: #1F1F1F">{{ parseTime(endDate, '{y}-{m}-{d}') }}</a>
      </template>

      <template slot="status" slot-scope="status">
        <a-tag v-if="status==='0'">未放款</a-tag>
        <a-tag v-else-if="status==='1'" color="green">正常</a-tag>
        <a-tag v-else-if="status==='6'">已结清</a-tag>
        <a-tag v-else color="red">逾期</a-tag>
      </template>


		</a-table>
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>

  import {moneyFormat} from "@/utils/amountFormat";
  import {parseTime} from "@/utils/ruoyi";

  export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
    methods: {
      parseTime,
      moneyFormat,
    }
	})

</script>