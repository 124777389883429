<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

    <a-col :span="24" :md="16">
      <a-row type="flex" :gutter="24">
        <a-col :span="24" :xl="24" class="mb-24">

          <!-- Master Card -->
          <CardCredit></CardCredit>
          <!-- / Master Card -->

        </a-col>
      </a-row>
    </a-col>

		<!-- Projects Table -->
		<a-row :gutter="24">

			<!-- Projects Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Projects Table Column -->
				<CardProjectTable2
					:data="Data"
					:columns="Columns"
				></CardProjectTable2>
				<!-- / Projects Table Column -->

			</a-col>
			<!-- / Projects Table Column -->

		</a-row>
		<!-- / Projects Table -->

	</div>
</template>

<script>


	// "Projects" table component.
	import CardProjectTable2 from '@/components/Cards/CardProjectTable2' ;
  import {getLoans} from "@/api/system/client";
  import CardCredit from "@/components/Cards/CardCredit.vue";

	
	// "Projects" table list of columns and their properties.
	const Columns = [
		{
			title: '贷款编号',
			dataIndex: 'loanId',
      scopedSlots: { customRender: 'loanId' },
			class: 'font-semibold text-muted text-sm',
			width: 150,
		},
    {
      title: '贷款类型',
      dataIndex: 'mode',
			class: 'font-semibold text-muted text-sm',
      width: 130,
    },
    {
      title: '还款方式',
      dataIndex: 'repayType',
      scopedSlots: { customRender: 'repayType' },
			class: 'font-semibold text-muted text-sm',
      width: 100,
    },
		{
			title: '贷款金额',
			dataIndex: 'price',
      scopedSlots: { customRender: 'price' },
			class: 'font-semibold text-muted text-sm',
      width: 130,
		},
    {
      title: '剩余本金',
      dataIndex: 'restMoney',
      scopedSlots: { customRender: 'restMoney' },
			class: 'font-semibold text-muted text-sm',
      width: 130,
    },
    {
      title: '贷款利率',
      dataIndex: 'rate',
      scopedSlots: { customRender: 'rate' },
			class: 'font-semibold text-muted text-sm',
      width: 100,
    },
    {
      title: '贷款期数',
      dataIndex: 'term',
      scopedSlots: { customRender: 'term' },
      class: 'font-semibold text-muted',
      width: 100,
    },
    {
      title: '贷款起始日期',
      dataIndex: 'startDate',
      scopedSlots: { customRender: 'startDate' },
      class: 'font-semibold text-muted text-sm',
			width: 130,
    },
    {
      title: '贷款到期日期',
      dataIndex: 'endDate',
      scopedSlots: { customRender: 'endDate' },
      class: 'font-semibold text-muted text-sm',
			width: 130,
    }
	];

	// "Projects" table list of rows and their properties.
	const Data = [
		{
			key: '1',
			company: {
				name: 'Spotify Version',
				logo: 'images/logos/logo-spotify.svg',
			},
			status: "working",
			budget: '$14,000',
			completion: 60,
		},
		{
			key: '2',
			company: {
				name: 'Progress Track',
				logo: 'images/logos/logo-atlassian.svg',
			},
			status: "working",
			budget: '$3,000',
			completion: 10,
		},
		{
			key: '3',
			company: {
				name: 'Jira Platform Errors',
				logo: 'images/logos/logo-slack.svg',
			},
			status: "done",
			budget: 'Not Set',
			completion: {
				status: 'success',
				value: 100,
			},
		},
		{
			key: '4',
			company: {
				name: 'Launch new Mobile App',
				logo: 'images/logos/logo-spotify.svg',
			},
			status: "canceled",
			budget: '$20,600',
			completion: {
				status: 'exception',
				value: 50,
			},
		},
		{
			key: '5',
			company: {
				name: 'Web Dev',
				logo: 'images/logos/logo-webdev.svg',
			},
			status: "working",
			budget: '$4,000',
			completion: 80,
		},
		{
			key: '6',
			company: {
				name: 'Redesign Online Store',
				logo: 'images/logos/logo-invision.svg',
			},
			status: "canceled",
			budget: '$2,000',
			completion: {
				status: 'exception',
				value: 0,
			},
		},
	];

	export default ({
		components: {
			CardProjectTable2,
      CardCredit,
		},
		data() {
			return {

				// Associating "Projects" table data with its corresponding property.
				Data: Data,

				// Associating "Projects" table columns with its corresponding property.
        Columns: Columns,
			}
		},
    created() {
      this.getList();
    },
    methods: {
      getList() {
        getLoans().then(response => {
          this.Data = response.rows;
        });
      }
    },
	})

</script>

<style lang="scss">
</style>