// 查询用户个人信息
import request from "@/utils/request";

export function getUserProfile() {
    return request({
        url: '/client/user/profile',
        method: 'get'
    })
}


export function getLoans() {
    return request({
        url: '/client/loan/list',
        method: 'get'
    })
}

export function getRepays(loanId) {
    return request({
        url: '/client/loan/repay/' + loanId,
        method: 'get',
    })
}